<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 년도 -->
          <c-datepicker
            name="year"
            type="year"
            label="점검년도"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
          <!-- 계획/무계획 -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="checkNoPlanItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="noPlanResultEnrollFlag"
            label="계획/무계획"
            v-model="searchParam.noPlanResultEnrollFlag"
          ></c-select>
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 결과 -->
          <c-select
            type="search"
            codeGroupCd="MIM_CHECK_RESULT_CD2"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="결과"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="실행기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="flagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="lawEquipmentFlag"
            label="법정설비여부"
            v-model="searchParam.lawEquipmentFlag"
          ></c-select>
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="typeItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipmentType"
            label="현황분류"
            v-model="searchParam.equipmentType"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            codeGroupCd="REGULATION_BILL_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="relatedLawList"
            maxValues="3"
            v-model="searchParam.relatedLawList">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비점검/정비완료 현황 목록"
      tableId="equipmentCd"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'resultName'">
          <div v-if="props.row['resultName'] === '부적합'" class="description-td">
            {{ props.row['resultName'] }}
          </div>
          <div v-else>
            {{ props.row['resultName'] }}
          </div>
        </template>
        <template v-if="col.name === 'statusTypeCd'">
          <q-chip outline square
            :color="setTagColor(props.row.statusTypeCd)"
            :clickable="true"
            text-color="white"
            @click="linkClick(props.row, { name: 'statusTypeCd' })"
          >
            {{setTagName(props.row.statusTypeCd)}}
          </q-chip>
        </template>
        <template v-if="col.name === 'equipmentCdName'">
          <q-btn
            class="tableinnerBtn"
            flat
            :editable="editable"
            align="center"
            color="blue-6"
            :label="props.row[col.name]"
            @click.stop="linkClick(props.row, col)" />
            <br>
          <!-- <q-btn
            class="glossy"
            round 
            size="sm"
            :editable="editable"
            color="blue-6"
            icon="search"
            @click.stop="linkClick(props.row)" /> -->
        </template>
      </template>
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import CDatepicker from '../../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: "equipment-inspection",
  data() {
    return {
      grid: {
        merge: [
          // { index: 0, colName: "plantName" },
          { index: 1, colName: "equipmentCdName"},
        ],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            label: "설비명(관리번호)",
            style: 'width:150px',
            align: "center",
            sortable: false,
            type: "custom",
          },
          {
            name: "statusTypeCd",
            field: "statusTypeCd",
            label: "현황분류",
            style: 'width:50px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "deptName",
            field: "deptName",
            label: "점검부서/정비입회자",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "typeName",
            field: "typeName",
            label: "점검/정비 유형",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "cycleName",
            field: "cycleName",
            label: "주기",
            style: 'width:50px',
            align: "center",
            sortable: false,
          },
          {
            label: '점검/정비',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "realDate",
                field: "realDate",
                label: "실행일",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
              {
                name: "userName",
                field: "userName",
                label: "담당자",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
              {
                name: "resultName",
                field: "resultName",
                label: "결과",
                style: 'width:60px',
                type: 'custom',
                align: "center",
                sortable: false,
              },
            ]
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:200px',
            align: "left",
            sortable: false,
          },
        ],
        data: [],
      },
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      searchParam: {
        plantCd: null,
        // 설비점검 start
        safCheckTypeCd1: null,
        // 설비정비 start
        safCheckTypeCd2: null, 
        resultCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        relatedLawList: [],
        lawEquipmentFlag: null,
        equipmentType: null,
        year: '',
      },
      editable: true,
      period: [],
      type: '1',
      checkNoPlanItems: [
        { code: 'Y', codeName: '무계획' },
        { code: 'N', codeName: '계획' }
      ], // 점검진행상태
      typeItems: [
        { code: '1', codeName: '점검' },
        { code: '2', codeName: '정비' },
      ],
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '점검', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '정비', colorClass: 'orange' },
      ],
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.min.equipment.maintain.list.url;
      // url setting
      // code setting
      // list setting
      this.searchParam.year = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      if (col && col.name === 'equipmentCdName') {
        this.popupOptions.param = {
          equipmentCd: row ? row.equipmentCd : '',
        }
        this.popupOptions.title = "설비마스터 상세"; // 설비점검/정비 현황
        this.popupOptions.target = () =>
          import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      } else if(col && col.name === 'statusTypeCd') {
        // if (row.statusTypeCd === 'N') { 
        //   this.popupOptions.title = '설비정비결과 상세'; // 설비점검계획 상세
        //   this.popupOptions.param = {
        //     minEquipmentMaintenanceId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
        //     selectData: [],
        //     title: '설비정비결과',
        //   };
        //   this.popupOptions.target = () =>
        //     import(`${"@/pages/sop/mim/maintenance/equipmentMaintenanceResultDetail.vue"}`);
        // } else {
        this.popupOptions.title = row.equipmentCdName + "의 설비점검/정비 현황"; // 설비점검/정비 현황
        this.popupOptions.param = {
          statusTypeName: row.statusTypeName,
          minEquipmentCheckId: row.minEquipmentCheckId ? row.minEquipmentCheckId : '',
          equipmentCd: row.equipmentCd,
          realDate: row.realDate,
          year: this.searchParam.year,
        }
        this.popupOptions.target = () =>
          import(`${"./equipmentRecord.vue"}`);
        // }
      } 
      // else {
      //   this.popupOptions.target = () =>
      //   import(`${"./equipmentRecord.vue"}`);
      //   this.popupOptions.title = "설비점검/정비 현황"; // 설비점검/정비 현황
      //   this.popupOptions.param = {
      //     equipmentCd: row.equipmentCd,
      //     year: this.searchParam.year,
      //   }
      // }
      this.popupOptions.visible = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnCancel() {
      this.isResult = false;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
